import { ICompensationRecord, IContractRecord, IContractServiceAndMaterialRecord, IGeneralNote, IGuaranteeDashBoard } from '@/common/types/contract';
import { IFilterModel, IPagedResultModel } from '@/common/types/filterModel';
import { httpClient } from './httpClient';
import { IApiErrorModel, IAssetRecord, IOperationResultModel } from './models/asset';
import { IDeleteDocumentValue, IDocumentViewModel, IPayrollVendorRecord, StaffViewModel } from './models/company';
import { ICompensationMutationPayload, IContractServiceAndMaterialMutationPayload, IDocumentValue, IGuaranteeContractDetails, IGuaranteeTermPaymentMutateModel, IGuaranteeTermPaymentRecord, IGuaranteeTrackingModelRecord, IMasterGuaranteeTotalAllocationModel } from './models/contract';
import { ITermPaymentInstalment } from '@/modules/contracts/components/tabs/guaranteePayment/components/termInstallmentForm';
import { ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import { IAgencyInfo, IPersonRecord, IZipCodeResponse } from './models/person';
import { ISelectedPerson } from '@/store/company/types';

export const saveGuaranteePayment = (payload: any) => {
	return httpClient.post(`/Contract/${payload.contractId}/GuaranteePayment/Save`, payload);
};

export const getAllContractForListView = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IContractRecord>>('/Contract/GetByForListView', filterModel);
};

export const calculateExpiryDateForCommercials = (contractId: string) => {
	return httpClient.post<IPagedResultModel<IContractRecord>>(`/Contract/CalculateExpiryDateForCommercials/${contractId}`);
};

export const getContractGetById = (contractId: string) => {
	return httpClient.post<IContractRecord>(`/Contract/GetById/${contractId}`);
};

export const updateRecord = (payload: any) => {
	return httpClient.post(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/UpdateRecord`, payload);
};

export const getMasterRecords = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<any>>('/Contract/GuaranteePayment/Master/Record/GetAll', filterModel);
};

export const getMasterRecordsForAsset = (assetId: string, filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<any>>(`/Contract/GuaranteePayment/Master/Record/GetAllForAsset/${assetId}`, filterModel);
};

export const getGuaranteeRecordsForAsset = (talentId: string) => {
	return httpClient.get<any>(`/Asset/GetGuaranteeRecordsForAsset/${talentId}`);
};

export const getGuaranteeRecords = (guaranteePaymentId: string, filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<any>>(`/Contract/GetGuaranteeRecords/${guaranteePaymentId}`, filterModel);
};

export const getMasterRecordsForCr = (completionReportId: string, filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<any>>(`/Contract/GuaranteePayment/Master/Record/GetAllForCr/${completionReportId}`, filterModel);
};

export const getPaymentRecords = (payload: any) => {
	return httpClient.post(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/TermAndPayments/Record/GetAll`, payload.filterModel);
};

export const getInvoices = (payload: any) => {
	return httpClient.post(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/UseApplied/Record/GetAll`, payload.filterModel);
};

export const getUtilizations = (payload: any) => {
	return httpClient.post(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/Utilization/Record/GetAll`, payload.filterModel);
};

export const saveTermAndPaymentsRecord = (payload: any) => {
	return httpClient.post(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/TermAndPayments/Record/Save`, payload);
};

export const saveMasterRecord = (payload: any) => {
	return httpClient.post(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/Master/Record/Save`, payload);
};

export const deleteMasterRecord = (payload: any) => {
	return httpClient.delete(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/Master/Record/Delete`, payload);
};

export const deleteAllocationRecord = (payload: any) => {
	return httpClient.delete(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/Allocation/Record/Delete`, payload);
};

export const deletePaymentRecord = (payload: any) => {
	return httpClient.delete(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/TermAndPayments/Record/Delete`, payload);
};

export const deleteInvoice = (payload: any) => {
	return httpClient.delete(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/UseApplied/Record/Delete`, payload);
};

export const deleteUtilizationRecord = (payload: any) => {
	return httpClient.delete(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/Utilization/Record/Delete`, payload);
};

export const saveGuaranteeRecord = (payload: any) => {
	return httpClient.post<IOperationResultModel>(`/Contract/GuaranteeRecord/Save/${payload.guaranteePaymentId}`, payload);
};

export const mutateGuaranteeTermAndPayment = (guaranteePaymentId: string, payload: IGuaranteeTermPaymentMutateModel) => {
	return httpClient.post(`/Contract/GuaranteePayment/${guaranteePaymentId}/TermAndPayment/Mutate`, payload);
};

export const getTermAndPaymentByGuaranteeId = (guaranteePaymentId: string) => {
	return httpClient.get<IGuaranteeTermPaymentRecord | undefined>(`/Contract/GuaranteePayment/${guaranteePaymentId}/TermAndPayment`);
};

export const getTermAndPaymentInstallments = (termPaymentId: string, filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ITermPaymentInstalment>>(`/Contract/TermAndPayment/${termPaymentId}/Installment/GetAll`, filterModel);
};

export const updateTermAndPaymentInstall = (termInstalmentId: string, payload: ITermPaymentInstalment) => {
	return httpClient.post<IOperationResultModel>(`/Contract/TermAndPayment/${termInstalmentId}/Installment/Update`, payload);
};

export const mutateTermAndPaymentInstallment = (termPaymentId: string, payload: ITermPaymentInstalment[]) => {
	return httpClient.post<IOperationResultModel>(`/Contract/TermAndPayment/${termPaymentId}/Installment/Mutate`, payload);
};

export const deleteInstalmentPayment = (termInstalmentId: string) => {
	return httpClient.delete<IOperationResultModel>(`/Contract/TermAndPayment/Installment/${termInstalmentId}/Delete`);
};

export const saveInvoice = (payload: any) => {
	return httpClient.post(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/UseApplied/Record/Save`, payload);
};

export const saveUtilization = (payload: any) => {
	return httpClient.post(`/Contract/GuaranteePayment/${payload.guaranteePaymentId}/Utilization/Record/Save`, payload);
};

export const mutateGuaranteeNote = (guaranteePaymentId: string, note: string) => {
	return httpClient.post(`/Contract/GuaranteePayment/${guaranteePaymentId}/Note/Mutate`, note);
};

export const addContract = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/Add', payload);
};

export const addContractSagAftraSdEmpCtrComPriPerY22 = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractSagAftraSdEmpCtrComPriPerY22/Add', payload);
};

export const addContractTeamSagAftraSdEmpCtrCom = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractTeamSagAftraSdExtCom/Add', payload);
};

export const addContractSagAftraSdEmpCtrIndEduFilmVideoPgm = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractSagAftraSdEmpCtrIndEduFilmVideoPgm/Add', payload);
};

export const addContractSagAftraCOED = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractSagAftraCOED/Add', payload);
};

export const addContractTeamSagAftraCOED = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractTeamSagAftraCOED/Add', payload);
};

export const addContractSagAftraTvPrincipal = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractSagAftraTvPrincipal/Add', payload);
};

export const addContractSagAftraSdExtComEx2Y22 = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractSagAftraSdExtComEx2Y22/Add', payload);
};

export const addContractSagAftraSdAudCom = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractSagAftraSdAudCom/Add', payload);
};

export const addContractSagAftraAudioComHT = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractSagAftraAudioComHT/Add', payload);
};

export const addContractSagAftraTvExtraHT = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractSagAftraTvExtraHT/Add', payload);
};

export const contractAddTeamSagAftraSdAudCom = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractTeamSagAftraSdAudCom/Add', payload);
};

export const addContractNonUnion = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractNonUnion/Add', payload);
};

export const addContractMSA = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/ContractMSA/Add', payload);
};

export const mutateW4Form = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/W4Form/Mutate', payload);
};

export const mutateW8BenShortForm = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/W8BenShortForm/Mutate', payload);
};

export const mutateW9BenShortForm = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/W9BenShortForm/Mutate', payload);
};

export const mutateMinorTrustForm = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/MinorTrustForm/Mutate', payload);
};

export const mutateNYTheftPreventionForm = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/NYTheftPreventionForm/Mutate', payload);
};

export const mutateCATheftPreventionForm = (payload) => {
	return httpClient.post<IOperationResultModel>('/Contract/CATheftPreventionForm/Mutate', payload);
};

export const updateContract = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/Update/${contractId}`, payload);
};

export const updateContractSagAftraSdEmpCtrComPriPerY22 = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/ContractSagAftraSdEmpCtrComPriPerY22/Update/${contractId}`, payload);
};

export const updateContractSagAftraSdEmpCtrIndEduFilmVideoPgm = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/UpdateContractSagAftraSdEmpCtrIndEduFilmVideoPgm/Update/${contractId}`, payload);
};

export const updateContractSagAftraCOED = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/UpdateContractSagAftraCOED/Update/${contractId}`, payload);
};

export const updateContractTeamSagAftraCOED = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/ContractTeamSagAftraCOED/Update/${contractId}`, payload);
};

export const updateContractSagAftraTvPrincipal = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/UpdateContractSagAftraTvPrincipal/Update/${contractId}`, payload);
};

export const updateUpdateContractSagAftraSdExtComEx2Y22 = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/UpdateContractSagAftraSdExtComEx2Y22/Update/${contractId}`, payload);
};

export const updateContractSagAftraSdAudCom = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/UpdateContractSagAftraSdAudCom/Update/${contractId}`, payload);
};

export const updateContractSagAftraAudioComHT = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/UpdateContractSagAftraAudioComHT/Update/${contractId}`, payload);
};

export const updateContractSagAftraTvExtraHT = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/UpdateContractSagAftraTvExtraHT/Update/${contractId}`, payload);
};

export const updateContractTeamSagAftraSdAudCom = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/UpdateContractTeamSagAftraSdAudCom/Update/${contractId}`, payload);
};

export const updateContractTeamSagAftraSdExtCom = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/ContractTeamSagAftraSdExtCom/Update/${contractId}`, payload);
};

export const updateContractNonUnion = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/UpdateContractNonUnion/Update/${contractId}`, payload);
};

export const updateContractMSA = (contractId: string, payload) => {
	return httpClient.post<IOperationResultModel>(`/Contract/ContractMSA/Update/${contractId}`, payload);
};

export const saveSnippet = (payload) => {
	return httpClient.post('/Contract/Snippet/Save', payload);
};

export const saveTemplate = (payload) => {
	return httpClient.post('/Contract/Template/Save', payload);
};

export const getGuaranteePaymentInformation = (payload: any) => {
	return httpClient.post(`/Contract/${payload.contractId}/GuaranteePayment/GetAll`, payload);
};

export const getSnippets = (payload: any) => {
	return httpClient.post('/Contract/Snippet/GetAll', payload);
};

export const getTemplates = (payload: any) => {
	return httpClient.post('/Contract/Template/GetAll', payload);
};

export const getTemplate = (templateId: any) => {
	return httpClient.get(`/Contract/Template/${templateId}/Get`);
};

export const deleteSnippet = (snippetId: any) => {
	return httpClient.delete(`/Contract/Snippet/${snippetId}/Delete`);
};

export const deleteTemplate = (templateId: any) => {
	return httpClient.delete(`/Contract/Template/${templateId}/Delete`);
};

export const createContractDocument = (payload: IDocumentValue) => {
	return httpClient.post('Contract/Document/Save', payload);
};

export const getContractDocumentList = (filterModel: IFilterModel, id:any) => {
	return httpClient.post<IPagedResultModel<IDocumentViewModel>>(`Contract/Documents/${id}`, filterModel);
};

export const deleteContractDocument = (payload: IDeleteDocumentValue) => {
	return httpClient.delete('Contract/Document/Delete', payload);
};

export const saveDealPoint = (payload: any) => {
	return httpClient.post('/Contract/DealPoint/Save', payload);
};

export const getDealPointsByContract = (contractId: any) => {
	return httpClient.get(`/Contract/DealPoint/${contractId}`);
};

export const GetContractGuaranteeDashBoardDetail = (contractId: string) => {
	return httpClient.get<IGuaranteeDashBoard | undefined>(`/Contract/${contractId}/GuaranteePayment/Dashboard`);
};

export const deleteDealPoint = (payload:any) => {
	return httpClient.delete('/Contract/DealPoint/Delete', payload);
};

export const savePlannedDealPoint = (payload: any) => {
	return httpClient.post('/Contract/PlannedDealPoint/Save', payload);
};

export const deletePlannedDealPoint = (payload:any) => {
	return httpClient.delete('/Contract/PlannedDealPoint/Delete', payload);
};

export const saveMiscellaneous = (payload: any) => {
	return httpClient.post('/Contract/Miscellaneous/Save', payload);
};

export const getMiscellaneousByContract = (contractId: any) => {
	return httpClient.get(`/Contract/Miscellaneous/${contractId}`);
};

export const deleteMiscellaneous = (payload:any) => {
	return httpClient.delete('/Contract/Miscellaneous/Delete', payload);
};

export const updateContractExpirationDate = (payload: any) => {
	return httpClient.post('Contract/UpdateContractExpirationDate', payload);
};

export const addMultipleContract = (payload) => {
	return httpClient.post('/Contract/AddMultipleContract', payload);
};

export const copyContract = (payload) => {
	return httpClient.post('/Contract/CopyContract', payload);
};

export const editContractStatus = (payload: any) => {
	return httpClient.post('Contract/EditContractStatus', payload);
};

export const updateContractPdfBlob = (payload: { contractId: string, blobName: string}) => {
	return httpClient.post('Contract/UpdateContractPdfBlob', payload);
};

export const getPossibleNextContractNumber = () => {
	return httpClient.get<number>('Contract/GetPossibleNextContractNumber');
};

export const mutateCompensation = (body: ICompensationMutationPayload[]) => {
	return httpClient.post('Contract/Compensation/Mutate', body);
};

export const getByCompensationForContract = (contractId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompensationRecord>>(`Contract/Compensation/GetBy/${contractId}`, filters);
};

export const deleteContractCompensation = (compensationId: string) => {
	return httpClient.delete(`Contract/Compensation/Delete/${compensationId}`);
};

export const deleteContract = (contractId: string) => {
	return httpClient.delete(`/Contract/${contractId}`);
};

export const mutateContractFreeForm = (contractId: string, body: IGeneralNote) => {
	return httpClient.post(`Contract/FreeForm/Mutate/${contractId}`, body);
};

export const mutateContractNote = (contractId: string, body: IGeneralNote) => {
	return httpClient.post(`Contract/Note/Mutate/${contractId}`, body);
};

export const mutateContractSpecialProvision = (contractId: string, body: IGeneralNote) => {
	return httpClient.post(`Contract/SpecialProvision/Mutate/${contractId}`, body);
};

export const getContractFreeForm = (contractId: string) => {
	return httpClient.get<IGeneralNote>(`Contract/FreeForm/GetByContractId/${contractId}`);
};

export const getContractNote = (contractId: string) => {
	return httpClient.get<IGeneralNote>(`Contract/Note/GetByContractId/${contractId}`);
};

export const getContractContractSpecialProvision = (contractId: string) => {
	return httpClient.get<IGeneralNote>(`Contract/SpecialProvision/GetByContractId/${contractId}`);
};

export const createContractRightsAcquired = (payload) => {
	return httpClient.post('/Contract/AddRightsAcquired', payload);
};

export const updateContractRightsAcquired = (payload) => {
	return httpClient.put('/Contract/UpdateRightsAcquired', payload);
};

export const getContractRenewalTerms = (filterModel: IFilterModel, contractId: string) => {
	return httpClient.post<IPagedResultModel<any>>(`Contract/GetRenewalTerms/${contractId}`, filterModel);
};

export const getContractRightsAcquired = (filterModel: IFilterModel, contractId: string) => {
	return httpClient.post<IPagedResultModel<any>>(`Contract/GetRightsAcquired/${contractId}`, filterModel);
};

export const addContractRenewalTerms = (payload) => {
	return httpClient.post('/Contract/AddRenewalTerms', payload);
};

export const deleteContractRightsAcquired = (payload) => {
	return httpClient.delete('/Contract/DeleteRightsAcquired', payload);
};

export const mutatePerformerBankTaxInfo = (contractId: string, bankTaxInfoIds: string[]) => {
	return httpClient.post(`/Contract/MutatePerformerBankTaxInfo/${contractId}`, bankTaxInfoIds);
};

export const GetBankDetails = (payload: any) => {
	return httpClient.get(`/Contract/Bank/${payload.bankId}`);
};

export const GetAllBankDetailsOfCompany = (payload: any) => {
	return httpClient.post(`/Contract/Bank/GetAll/${payload.companyId}`);
};

export const getByForContractResourceService = (contractId: string, filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IContractServiceAndMaterialRecord>>(`/Contract/Resource/Service/GetBy/${contractId}`, filterModel);
};
export const getByForContractResourceMaterial = (contractId: string, filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IContractServiceAndMaterialRecord>>(`/Contract/Resource/Material/GetBy/${contractId}`, filterModel);
};

export const mutateContractResourceMaterial = (contractId: string, payload: IContractServiceAndMaterialMutationPayload) => {
	return httpClient.post(`/Contract/Resource/Material/Mutate/${contractId}`, payload);
};

export const mutateContractResourceService = (contractId: string, payload: IContractServiceAndMaterialMutationPayload) => {
	return httpClient.post(`/Contract/Resource/Service/Mutate/${contractId}`, payload);
};

export const deleteContractResourceMaterial = (contractResourceId: string) => {
	return httpClient.delete<IApiErrorModel>(`/Contract/Resource/Material/Delete/${contractResourceId}`);
};

export const deleteContractResourceService = (contractResourceId: string) => {
	return httpClient.delete<IApiErrorModel>(`/Contract/Resource/Service/Delete/${contractResourceId}`);
};

export const addAgentRepresentation = (payload) => {
	return httpClient.post('/Contract/AddAgentRepresentation', payload);
};

export const getAgentRepresentation = (filterModel: IFilterModel, contractId: string) => {
	return httpClient.post<IPagedResultModel<any>>(`Contract/GetAgentRepresentation/${contractId}`, filterModel);
};

export const deleteContractAgentRepresentation = (body: any) => {
	return httpClient.deleteV2<IApiErrorModel>('/Contract/DeleteContractAgentRepresentation',{headers: {},body});
};

export const saveBankForCompany = (payload: any) => {
	return httpClient.post('/Contract/Bank/Save', payload);
};

export const getAllContractOfPersonByAssetId = (personId: string, assetId: string) => {
	return httpClient.get<IContractRecord[] | undefined>(`/Contract/GetAllContractOfPersonByAssetId/${personId}/${assetId}`);
};

export const bulkContractSave = (payload: any) => {
	return httpClient.post('/Contract/BulkAdd', payload);
};

export const getMasterGuaranteeTotalAllocation = (guaranteePaymentId: string) => {
	return httpClient.get<IMasterGuaranteeTotalAllocationModel>(`/Contract/GuaranteePayment/${guaranteePaymentId}/Master/TotalAllocation`);
};

export const getNextGuaranteeNumber = (guaranteePaymentId: string) => {
	return httpClient.get<number>(`/Contract/GuaranteePayment/${guaranteePaymentId}/NextNumber`);
};

export const getNextGuaranteeRecordNumber = (guaranteePaymentId: string) => {
	return httpClient.get<number>(`Contract/GetNextGuaranteeRecordNumber/${guaranteePaymentId}`);
};

export const getContractGuaranteeTracking = (contractId: string, filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IGuaranteeTrackingModelRecord>>(`/Contract/${contractId}/GuaranteeTracking/GetBy`, filterModel);
};

export const quickContractSave = (payload: any) => {
	return httpClient.post('/Contract/QuickAdd', payload);
};

export const getBaseContractDetails = (filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IContractRecord>>('/Contract/BaseContract/GetBy', filters);
};

export const getGuaranteeContractByGuaranteeNumber = (guaranteeNumbers: string[]) => {
	return httpClient.post<IGuaranteeContractDetails[]>('/Contract/GuaranteeDetailByGuaranteeNumber', guaranteeNumbers);
};

export const bulkUpdateContracts = (payload: any) => {
	return httpClient.post('Contract/BulkUpdateContracts', payload);
};

export const getAdAgencyAdvertiser = (filters: IFilterModel, id: string) => {
	return httpClient.post<IPagedResultModel<any>>(`/Contract/CompanyAdAgencyAdvertiser/Get/${id}`, filters);
};

export const getAllAddressByCompany = (filterModel: IFilterModel, companyId: string) => {
	return httpClient.post<IPagedResultModel<ICompanyAddress>>(`/Contract/CompanyAddress/GetByCompany/${companyId}`, filterModel);
};

export const getCompanyBasicDetails = (filterModel: IFilterModel, includeAllCompany: boolean = false) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('/Contract/CompanyGetBasicDetails', filterModel, { includeAllCompany: includeAllCompany || false });
};

export const getStaffListByCompany = (filterModel: IFilterModel,id:any) => {
	return httpClient.post<IPagedResultModel<StaffViewModel>>(`/Contract/GetAllStaff/${id}`, filterModel);
};

export const getAllProducts = (filterModel: any, companyId: any) => {
	return httpClient.post<IPagedResultModel<any>>(`/Contract/GetProducts/${companyId}`, filterModel);
};

export const addNewCompanyEmail = (addressId: string, payloadBody) => {
	return httpClient.post(`/Contract/CompanyAddress/Email/Save/${addressId}`,payloadBody);
};

export const getCompanyPrimaryEmailIdsForContract = (payload) => {
	return httpClient.post<any>('/Contract/CompanyEmails/GetByCompanyIds', payload);
};

export const createCompanyAddressPhoneNumber = (payload: any) => {
	return httpClient.post('/Contract/CompanyAddress/PhoneNumber/Save', payload);
};

export const getBusinessTaxInfo = (companyId: any) => {
	return httpClient.get(`/Contract/CompanyBusinessTaxInfo/${companyId}`);
};

export const getPayrollvendors = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IPayrollVendorRecord>>('/Contract/PayrollVendorDetailsGetBy', filterModel);
};

export const getAdvertiserWithAddresses = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('/Contract/GetAddressesForCompany', filterModel);
};

export const getAllCompanies = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('/Contract/ContractCompanies', filterModel);
};

export const getAllAssetForBasicDetail = (filters: IFilterModel, includeEditVersion: boolean = false) => {
	return httpClient.post<IPagedResultModel<IAssetRecord>>('/Contract/GetAllAssetForBasicDetail', filters, { includeEditVersion });
};

export const retriveAssetProducts = (filterModel: any) => {
	return httpClient.post<IPagedResultModel<any>>('/Contract/ProductAsset/GetAll', filterModel);
};

export const GetByWithFullNameSearchForBasicDetail = (filterModel: IFilterModel, searchText: string = '') => {
	return httpClient.post<IPagedResultModel<IPersonRecord>>('/Contract/GetByWithFullNameSearchForBasicDetailForContract', { filterModel, searchText });
};

export const getAgentInfo = (filterModel: IFilterModel, personId: string) => {
	return httpClient.post<IPagedResultModel<IAgencyInfo>>(`/Contract/GetAgentInfoForContract/${personId}`, filterModel);
};

export const getAllAddressByPerson = (filterModel: IFilterModel, personId: string) => {
	return httpClient.post<IPagedResultModel<ICompanyAddress>>(`/Contract/Address/GetByPersonForContract/${personId}`, filterModel);
};

export const getAddressBasedOnPinCode = (zipCode: string) => {
	return httpClient.get<IZipCodeResponse | null>(`/Contract/GetAddressBasedOnZipCodeForContract/${zipCode}`);
};

export const createPersonEmail = (payload: any) => {
	return httpClient.post('/Contract/EmailAdd/Save', payload);
};

export const createPersonPhoneNumber = (payload: any) => {
	return httpClient.post('/Contract/PhoneNumberAdd/Save', payload);
};

export const getByWithFullNameSearch = (filterModel: IFilterModel, searchText: string = '') => {
	return httpClient.post<IPagedResultModel<IPersonRecord>>('/Contract/GetByWithFullNameSearchPerson', { filterModel, searchText });
};

export const getPersonAgentDetails = (personId: any) => {
	return httpClient.get<any>(`/Contract/GetTalentAgentDetails/${personId}`);
};

export const getPersonById = (personId: any) => {
	return httpClient.get<ISelectedPerson>(`/Contract/GetPersonById/${personId}`);
};

export const getAllAgentInformation = (filters: IFilterModel, id: any) => {
	return httpClient.post<IPagedResultModel<IPersonRecord>>(`/Contract/GetAllAgentInformationForTalent/${id}`, filters);
};

export const getLoanOutCorporations = (personId: any, filterModel: any) => {
	return httpClient.post(`/Contract/LoanOutCorporations/${personId}`, filterModel);
};

export const getPersonTaxInformation = (personId: any) => {
	return httpClient.get(`/Contract/${personId}/TaxInfoForContract`);
};

export const addW4TaxInfo = (payload: any) => {
	return httpClient.post('/Contract/W4TaxAdd/Save', payload);
};

export const addW8TaxInfo = (payload: any) => {
	return httpClient.post('/Contract/W8TaxAdd/Save', payload);
};

export const addW9TaxInfo = (payload: any) => {
	return httpClient.post('/Contract/W9TaxAdd/Save', payload);
};

export const getAllBankDetails = (payload: any) => {
	return httpClient.post(`/Contract/BankPerson/GetAll/${payload.personId}`);
};