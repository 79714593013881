import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAppContextModel } from './types';

const initialState: IAppContextModel = {
	showLoader: false,
	disableLoader: false,
	showAutoLoader: false,
	totalRecord: 0,
	isEditOpen: false,
	isCRSubmitted: false,
	formName: '',
	notification: {
		showNotification: false,
		message: '',
		type: 'success'
	},
	backNavigationVisible: false,
	urlStack: [],
	profileImage: {
		profileBlobId: '',
		profileBlobUrl: '',
		profileType: '',
	}
};

export const appContextSlice = createSlice({
	name: 'appContext',
	initialState,
	reducers: {
		showNotification: (state, action) => {
			state.notification = {
				showNotification: true,
				message: action.payload.message,
				type: action.payload.type
			};
		},
		hideNotification: (state) => {
			state.notification.showNotification = false;
		},
		setLoader: (state, action) => {
			state.showLoader = !state.disableLoader && action.payload;
		},
		setAutoLoader: (state, action) => {
			state.showAutoLoader = action.payload;
		},
		setDisableLoader: (state, action) => {
			state.disableLoader = action.payload;
		},
		setIsEditOpen: (state, action) => {
			state.isEditOpen = action.payload;
		},
		setCRSubmitted:  (state, action) => {
			state.isCRSubmitted = action.payload;
		},
		setFormName: (state, action) => {
			state.formName = action.payload;
		},
		resetEditFormValidation: (state) => {
			state.isEditOpen = false;
			state.formName = '';
		},
		setTotalRecord: (state, action: PayloadAction<{totalRecord: number}>) => {
			state.totalRecord = action.payload.totalRecord;
		},
		setProfileTitle: (state, action: PayloadAction<{title: string}>) => {
			state.profileTitle = action.payload.title;
		},
		setProfileUrl: (state, action: PayloadAction<{url: string, blobId: string, profileType?: string}>) => {
			state.profileImage.profileBlobUrl = action.payload.url;
			state.profileImage.profileBlobId = action.payload.blobId;
			state.profileImage.profileType = action?.payload?.profileType || '';
		},
		forwardNavigation: (state, action:  PayloadAction<{visible: boolean, currentPath: string}>) => {
			
			if(!state.backNavigationVisible) 
				state.backNavigationStart = action.payload.currentPath;
			
			state.backNavigationVisible = action.payload.visible;
			
			if(action.payload.visible){
				state.urlStack.push(action.payload.currentPath);
			} else {
				state.backNavigationStart = undefined;
				state.urlStack = [];
			}
		},
		backwardNavigation : (state, action: PayloadAction<{updatedUrlStack: string[]}>) => {
			const urlStack = action.payload.updatedUrlStack;			
			if(urlStack.length) 
				state.urlStack = urlStack;
			else {
				state.urlStack = [];
				state.backNavigationStart = undefined;
				state.backNavigationVisible = false;
			}
		},
		clearBackNavigation : (state) => {
			state.urlStack = [];
			state.backNavigationStart = undefined;
			state.backNavigationVisible = false;
		},
	}
});

export const { 
	showNotification, 
	hideNotification, 
	setLoader, 
	setAutoLoader, 
	setDisableLoader, 
	setTotalRecord, 
	setProfileTitle, 
	forwardNavigation,
	backwardNavigation,
	clearBackNavigation,
	setIsEditOpen,
	setFormName,
	setProfileUrl,
	setCRSubmitted,
	resetEditFormValidation
} = appContextSlice.actions;
export default appContextSlice.reducer;